.product {
    width: 280px;
}

.addProduct {
    top: 0px;
    position: fixed;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}


.productFields {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 15px;
    width: 280px;
}

.teletherapyProduct {
    width: calc(min(100vh, 280px));
}

.teletherapyProductFields {
    justify-content: flex-start;
}

.modifySelectionList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.teletherapyModifyButtons {
    position: absolute;
    left: calc(50% + min(138px, 50vw - 60px));
    top: calc(50% - 45px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(3 * 35px + 6px);
    margin-left: 2px;
}

