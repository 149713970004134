.signIn {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background: white;
    z-index: 10;
}

.signInTitle {
    color: gray;
    padding-bottom: 20px;
    cursor: default;
}

.signInButtonContainer {
    padding-top: 20px;
}

.signInFields {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
}

.signInError {
    color: red;
    display: flex;
    justify-content: center;
    align-items: top;
    height: 80px;
    padding-top: 20px;
    max-width: 90vw;
}


.signInProgress {
    background-color: #e1e1e1;
    color: gray;
    display: flex;
    align-items:center;
    justify-content: center;
    width: 200px;
    font-size: 14px;
    height: 35px;
    z-index: 100;
}
