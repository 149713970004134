.uploadProgress {
    top: 0px;
    left: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: none;
    transition: width 1s ease;
    animation: progress-bar 0.5s linear infinite;
}

.uploadProgressContainer {
    filter: drop-shadow(0 0 0.75rem #BBBBBB);
    width: 30%;
}

.uploadProgressTitle {
    background: #efefef;
    width: 100%;
    padding: 5px;
    color: #2d2d2d;
    display: flex;
    justify-content: center;
    font-size: 15px;
}

.uploads {
    background: #efefef;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 5px;
    flex-direction: column
}

.upload {
    height: 30px;
    background: gray;
}

@keyframes progress-bar {
    0% {
	background-position: 40px 0;
    }
    100% {
	background-position: 0 0;
    }
}
