.incomingCall {
    top: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: white;
}

.incomingCallTitle {
    color: gray;
    padding-bottom: 20px;
    cursor: default;
}

.incomingCallSubtitle {
    font-size: 14px;
    cursor: default;
}

.incomingCallButtonContainer {
    padding-top: 20px;
}

.incomingCallFields {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

