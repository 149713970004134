.contactInfo {
    padding-top: 30px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.contactInfoTitle {
    cursor: default;
    color: gray;
    padding-bottom: 20px;
}

.paymentMethodTitle {
    cursor: default;
    color: gray;
    padding-top: 20px;
    padding-bottom: 10px;
}

.contactInfoButtonContainer {
    padding-top: 20px;
}

.contactInfoFields {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactInfoError {
    color: red;
    display: flex;
    justify-content: center;
    align-items: top;
    height: 80px;
    padding-top: 20px;
    max-width: 90vw;
}

.productListItem {
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #efefef;
    margin-bottom: -1px;
    padding: 10px;
}

.myContactInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.addProductButtonContainer {
    padding-top: 20px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
}


.productButtonContainer {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}


.stripeConnectMessage {
    padding-top: 30px;
    font-size: 14px;
    color: gray;
    width: calc(min(300px, 90vh));
}

.stripeConnectMessage p {
    text-align: center;
}

.stripeButtonContainer {
}


.stripeButtonConnectedContainer {
    padding-top: 25px;
    pointer-events: none;
}

.myTeteProductsTitle {
    cursor: default;
    color: gray;
}

.insCovGroup {
    border: 1px solid gray;
}


.stripeClientConnectForm {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #e1e1e1;
    padding-left: 15px;
    padding-right: 15px;
}
.cardName {
    color: gray;
    font-size: 14px;
    background: #e1e1e1;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    width: 265px;
    cursor: default;
}
.stripeConnectFormCard {
    width: 100%;
}

.purchaseMethodContainer {
}

.purchaseMethodDescription {
    font-size: 14px;
    color: gray;
}

.purchaseButtonContainer {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
}


.productAccepted {
    height: 35px;
    width: 90px;
    display: flex;
    align-items:center;
    justify-content: center;
    cursor: default;
    color: gray;
    margin-left: 2px;
    font-size: 15px;
}
