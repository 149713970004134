.lbContact {
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 4px;
}

.lbContactSelected {
    background: rgb(37, 183, 68);
    cursor: default;
}

.lbContactSelected:hover {
    background: rgb(37, 183, 68) !important;
}

.lbFitbitDeviceIcon {
    display: inline-block;
    height: 20px;
    width: 22px;
}

.lbFitbitDeviceIcon img {
    object-fit: contain;
    height: 15px;
    width: 15px;
    transform: translate(-0px, 0);
}

.lbContactFitbit .lbContactActivity svg {
    display: none;
}

.lbContactFitbit .lbContactActivity .lbContactScoreScore {
    visibility: hidden;
}

.lbContactFitbit .lbContactRecovery svg {
    visibility: hidden;
}

.lbContactFitbit .lbContactRecovery .lbContactScoreScore {
    visibility: hidden;
}

.lbContactFitbit .lbContactRecovery .lbContactScoreContent {
    background: rgb(251, 176, 60) !important;
}


.uiTeTeAppMobile .lbContact {
    padding-left: 10px;
    padding-right: 10px;
}


.lbContact:hover .lbContactSelectedArrow svg {
    fill: rgb(37, 183, 68);
}

.lbContact:hover {
    background:  #B6EEFF;
}


.lbContactUnread {
    position: absolute;
    z-index: 10;
    left: 100%;
    display: flex;
    height: 100%;
    min-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:  #f15a24;
    color: #e6e6e6;
    box-shadow: 2px 1px 2px 0px rgba(0,0,0,0.20);    
}

.lbContactUnread99Plus {
    padding-left: 2px;
    padding-right: 2px
}

.uiTeTeAppMobile .lbContactUnread {
    box-shadow: none;
    min-width: 30px;
}

.uiTeTeAppMobile .lbContactUnread {
    right: 0px;
    left: auto;
}

.lbContactRow1 {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 100%;
}

.lbContactRow1Left {
    height: 60px;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uiTeTeAppMobile .lbContactRow1Left {
    height: 65px;
}

.lbContactRow1Right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.lbContactInfo {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    height: 100%;
    white-space: nowrap;
    transform: translate(-5px, -10px);
}


.lbContactStatusAccepted {
    color: rgb(37, 183, 68);
}

.lbContactStatusDeclined {
    color: rgb(184, 38, 26);
}

.lbContactStatusScheduled {
    color: rgb(251, 176, 60);
}

.lbContactSelected .lbContactStatusAccepted {
    color: #11D316
}


.uiTeTeAppMobile .lbContactInfo {
    transform: translate(-22.5px, -10px);
}

.lbContactRow1Middle {
    display: flex;
    min-height: 100%;
    justify-content: flex-start;
    align-items: center;
    max-width: calc(100% - 60px);
}

.lbContactRow1MiddleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
}


.lbContactDeviceIcon {
    padding-right: 2px;
}

.lbContactDeviceIcon svg {
    height: 20px;
    width: 20px;
    fill: #A5A5A5;
}

.lbContactSelected .lbContactDeviceIcon svg {
    fill: #e4e4e4;
}

.uiTeTeAppMobile .lbContactDeviceIcon svg {
    height: 25px;
    width: 25px;
}

.lbContactDeviceIcon div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lbContactRow2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.lbContactScore {
    display: flex;
    align-items: center;
    color: white;
    height: 100%;
    width: 100%;
}

.lbContactScoreContent {
    display: flex;
    align-items: center;
    width: 100%;
}

.lbContact .uiScorePoor {
    padding-left: 0;
}

.lbContact .uiScoreAverage {
    padding-left: 0;
}

.lbContact .uiScoreOptimal {
    padding-left: 0;
}

.lbContactScoreScore {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 25px;
    padding-right: 5px;
    width: calc(100% - 5px);
    font-size: 16px;
    font-family: arial;
}

.uiTeTeAppMobile .lbContactScoreScore {
    font-size: 20px;
    height: 28px;
    padding-right: 10px;
    width: calc(100% - 10px);
}

.lbContactScoreIcon {
    padding-left: 5px;
}

.lbContactScoreIcon svg {
    height: 18px;
    width: 18px;
    fill: white;
}

.uiTeTeAppMobile .lbContactScoreIcon svg {
    height: 25px;
    width: 25px;
}

.uiTeTeAppMobile .lbContactScoreIcon  {
    padding-left: 10px;
}

.lbContactScoreIcon div  {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lbContactActivity {
    height: 100%;
    width: 100%;
    background: rgb(0, 113, 187);
}

.lbContactSleep {
    height: 100%;
    width: 100%;
}

.lbContactRecovery {
    height: 100%;
    width: 100%;
}

.lbContactWeight {
    height: 100%;
    background: rgb(36, 216, 190);
    width: 100%;
}

.uiScoreNoWeight svg {
    display: none
}

.uiScoreNoWeight {
    color: rgb(36, 216, 190);
}

.uiScoreNoAppt {
    color: transparent;
}

.lbContactName {
    font-size: 18px;
    color: #4a4a4a;
    max-width: 190px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.lbContactSelected .lbContactName {
    color: #f0f0f0;
}

.uiTeTeAppMobile .lbContactName {
    max-width: calc(100vw - 87px);
    font-size: 24px;
}

.lbContactSelected .lbContactCreds {
    color: #e4e4e4;
}

.lbContactCreds {
    font-size: 13px;
    color: #A5A5A5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    max-width: 100%;
}

.lbContactCredsCreds {
    overflow: hidden;
    text-overflow: ellipsis;

}
    

.uiTeTeAppMobile .lbContactCreds {
    font-size: 16px;
}

.uiSleepScoreOura svg {
    transform: scale(-1, 1);
}

.uiTeTeAppMobile .uiSideListListContent {
    padding-top: 0px;
}

.lbContactSelectedArrow {
    position: absolute;
    left: calc(100%);
    z-index: 11;
    transform: translate(0, -1px) scale(1, 1.3);
}


.lbContactSelectedArrow svg {
    /* height: 60px; */
    transform: translate(0, 7.5px);
    height: 45px;
    fill: rgb(37, 183, 68);
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.11));
}

.lbContactNoDevice .lbContactScoreContent {
    visibility: hidden;
}

.lbContactNoDevice .lbContactWeight .lbContactScoreContent {
    visibility: visible;
}
