.inputField {
    width: 280px;
}

.inputFieldAndLabel {
    display: flex;
    justify-content: space-between;
    margin: 1px 1px 1px 0 ;
}

.inputFieldDateInput {
    width: 60% !important;
    margin: 0 !important;
}

.inputFieldNumberInput {
    width: 220px !important;
    margin: 0 !important;
}

.inputFieldPhoneInput {
    width: 265px !important;
    margin: 0 !important;
}


.inputFieldLabel {
  color: gray;
  font-size: 14px;
  background: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  width: 40%;
  cursor: default;
}

.inputFieldCheckboxLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: gray;
  font-size: 14px;
  background: white;
  height: 35px;
  width:  250px;
  right: 0px;
  margin-left: -251px;
  
}

.inputFieldInput[type="checkbox"] {
  background: #e1e1e1;
  color: gray;
}

.inputFieldInput {
  border: 0;
  outline: none;
  height: 35px;
  width: 100%;
  background: #e1e1e1;
  color: gray;

  padding: 0 0 0 15px;
  font-size: 14px;
}

.inputFieldSelect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    overflow: hidden;
}

.inputFieldSelectLabel {
  height: 35px;
  font-size: 14px;
  color: gray;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  font-size: 14px;
  background: #e1e1e1;
}

.inputFieldSelectContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-1px, 0);
    padding-right: 15px;
    width: 150px;
    height: 35px;
    background: #e1e1e1;
}

.inputFieldSelect select {
    min-width: 165px;
    background-color: #e1e1e1;
    font-size: 14px;
    color: gray;
    border: none;
    border-radius: 0;

}

.inputFieldInput:read-only {
    cursor: default;
}

.inputFieldTimeInputContainer {
    position: relative;
    cursor: default;
}

.inputFieldAmPmSelector {
    font-size: 14px;
    color: gray;
    position: absolute;
    right: -20px;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    width: 45px;
    padding-right: 20px;
}
