.signUp {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signUpTitle {
    cursor: default;
    color: gray;
    padding-bottom: 20px;
}

.signUpButtonContainer {
    padding-top: 20px;
}

.signUpFields {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signUpError {
    color: red;
    display: flex;
    justify-content: center;
    align-items: top;
    height: 80px;
    padding-top: 20px;
    max-width: 90vw;
}
