.showLink {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
}

.showLinkTitle {
    color: gray;
    padding-bottom: 10px;
}

.showLinkSubtitle {
    color: #101010;
    padding-bottom: 20px;
    font-size: 12px;
}

.showLinkButtonContainer {
    padding-top: 20px;
}

.showLinkFields {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showLinkContainer {
    max-width: 90%;
    background: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.showLinkText {
    color: #02029e;
    font-size: 14px;
    padding: 20px;
    
}
