.selectionList {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 5;
}

.selectionListSearch {
    padding-top: 10px;
    padding-bottom: 10px;
}

.selectionListButtonContainer {
    padding-top: 15px; 
    padding-bottom: 15px; 
    display: flex;
}
.selectionListContainer {
    height: 100%;    
    background: #b3b3b3;
    overflow: scroll;
}

.selectionList > inputField {
    width: 400px !important;
}

