.paymentMethod {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentMethodDetails {
    display: flex;
    flex-direction: column;
}
