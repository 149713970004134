.buttonContainer {
    height: 35px;
    display: flex;
}

.button {
    height: 35px;
    width: 90px;
    background: #efefef;
    display: flex;
    align-items:center;
    justify-content: center;
    cursor: pointer;
    color: gray;
    margin-left: 2px;
}

.buttonSmall {
    width: 65px !important;
}

.buttonExtraSmall {
    width: 50px !important;
}


.buttonLabel {
    user-select: none;
    font-size: 14px;
}

.buttonDisabled buttonDesktop:hover {
    background: #efefef !important;
    color: gray !important;
    cursor: default;
}

.buttonDesktop:hover {
    background: gray;
    color: white;
}
