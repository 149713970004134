::-webkit-scrollbar-button{
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b3b3b3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2f2f2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.uiTeTeAppContainer {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.uiTeTeApp {
    min-width: 100%;
    height: 100%;
    position: relative;
}

.client {
    height: 100%;
    width: 100%;
}

.app {
    top: 0px;
    left: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
}

.clientCall {
    height: 100%;
    width: 100%;
    position: fixed;
}

.videoVisibilityContainer {
    position: absolute;
    width: 100%;
    height: 100%;
}

.remoteVideosDesktop {
    height: 100%;
    width: 100%;
    display: grid;
    position: fixed;
    grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
    grid-row-gap: 1px;
    grid-auto-rows: minmax(23%, 1fr);
}

.remoteVideosMobile {
    height: 100%;
    width: 100%;
    display: grid;
    position: fixed;
    grid-template-rows: repeat(auto-fit, minmax(33%, 1fr));
    grid-row-gap: 1px;
    grid-auto-columns: minmax(23%, 1fr);
}


.remoteVideo {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.remoteVideoSmall {
   object-fit: cover;
   width: 125px;
   position: absolute;
   right: calc(125px + 10px);
   top: 5px;
   object-fit: cover;
   z-index: 2;
}

.localVideo {
   width: 125px;
   position: absolute;
   right: 5px;
   top: 5px;
   object-fit: cover;
   transform: scale(-1, 1);
   z-index: 10;
}

.controlsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: 100%;
}

.chatContainer {
    position: absolute;
    height: 100%;
    bottom: 45px;
    width: calc(100% - 2px);
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dcdcdc;
    min-height: 45px;
    z-index: 0;
}

.buttonControls {

    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
}

.buttonControls buttonContainer {
    margin-top: 4px;
}

.callBackButton {
    position: relative;
    top: 0px;
    left: 0px;
}

.callDuration {
    position: absolute;
    left: calc(50vw - 22.5px);
    bottom: 20px;
    z-index: 1;
    font-size: 9px;
    padding: 2px;
    bottom: 46px;
    background: gray;
    color: white;
    height: 10px;
    width: 30px;
    display: flex;
    justify-content: flex-end;
}

.callCost {
    position: absolute;
    left: 1px;
    bottom: 30px;
    z-index: 1;
    font-size: 9px;
    padding: 2px;
    bottom: 46px;
    background: gray;
    color: white;
    display: flex;
    justify-content: flex-end;
    width: 30px;
    height: 10px;
}

.callStats {
    height: 10px;
    width: 130px;
    position: absolute;
    right: 1px;
    bottom: 46px;
    z-index: 1;
    font-size: 9px;
    padding: 2px;
    background: gray;
    color: white;
    display: flex;
    justify-content: flex-end;
}

.appTitle {
    font-size: 20px;
    padding-bottom: 15px;
    background: #00000000;
}

.appTitle svg {
    height: 150px;
    width: 150px;
}

.uiClientMain {
    position: absolute;
    height: 100%;
    width: 100%;
    background: transparent;
}

.uiClientMainContainer {
    position: absolute;
    width: calc(100% - 270px);
    left: 270px;
    height: 100%;
    background: white;
    top: 0;
}

.stripeConnectPopup {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.appointmentDateTimePromptPopup {
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background: #00000000;
    z-index: 100;
}

.appointmentDate {
    font: 15px;
    color: gray;
    padding-bottom: 5px;
}

.appointmentDateWho {
    font-size: 14px;
    padding-bottom: 10px;
}

.appointmentDateTimePromptTitle {
    padding-top: 20px;
    color: gray;
    padding-bottom: 5px;
}

.appointmentDateTimePrompt {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.33);
}

.appointmentButtons {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-bottom: 10px;
}

.appointmentDateTimePrompt .inputField {
    width: 245px;
}

.appointmentDateTimePrompt .inputFieldLabel {
    width: 80%;
}

.uiOpenContactIncoming .uiSideListElementContainer {
    width: calc(100% - 45px);
}

.uiOpenContactIncoming .uiSideListMessageHangup {
    background: #CA0000 !important;
}

.uiOpenContactIncoming .uiSideListMessageHangup svg {
    fill: #e6e6e6;
}

.uiMeContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.uiClientProgressIndicatorContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;    
}

.uiClientProgressIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.33);
    z-index: 1000;
    background: white;
    height: 60px;
    width: 160px;
    position: relative;
}


.uiClientProgressIndicatorMessage {
    text-align: center;
    color: #666666;
    font-size: 14px;
}

.uiClientBlockInput {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    cursor: progress;
    z-index: 10000;
    display: block !important;
    background: rgb(0, 0, 0, 0.2);
}


.uiTeTeAppMobile .uiClientBlockInput {
    background: rgb(0, 0, 0, 0.3);
}


.uiSettingsContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.ant-icon-group {
    transform: scale(1.2857, 1.2857);
    transform-origin: center;
}
