.contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contactsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.contactsTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

.contactsTitleText {
    font-size: 18px;
    padding-bottom: 5px;
    cursor: default;
}

.contactsSubtitleText {
    color: gray;
    font-size: 14px;
    cursor: default;
}

.contactsSearch {
    padding-top: 10px;
}

.contactCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 20px);
    padding-left: 5px;
    background: #efefef;
    padding-right: 5px;
}

.unreadCount {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: gray;
    color: white;
    height: 16px;
    width: 16px;
    pointer-events: none;
}

.contactName {
    height: 35px;
    color: gray;
    display: flex;
    align-items:center;
    justify-content: flex-start;
    cursor: default;
    font-size: 14px;
    cursor: pointer;
    width: 100%
}

.contactName:hover {
    text-decoration:underline;
}

.contactCard {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.contactsContainer {
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
}

.contactsList {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.appTitleLeft {
    align-self: flex-start;
}
